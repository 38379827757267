import { graphql } from "gatsby";
import React from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import CallToAction from "../components/CallToAction";
import Box from "../components/elements/Box";
import Heading from "../components/elements/Heading";
import Text from "../components/elements/Text";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Paragraph from "../components/Paragraph";
import Section from "../components/Section";
import SEO from "../components/Seo";
import Table from "../components/Table";

const FeePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="買賣費用" />
      <PageHeader backgroundImage={data.bg.childImageSharp.fluid}>
        <Fade delay={100}>
          <Heading color="white" fontSize={[5, 7]} content="買賣費用"></Heading>
        </Fade>
      </PageHeader>

      <Section>
        <Paragraph>
          二手樓宇買賣需要支付 3.09%的交易稅，及其他費用（見下列表）。
          <p>
            如果購入新房，需要繳納
            24%的增值稅。新房的定義，是房屋從未被使用過或者交易過。而且，房屋的建造許可是在
            2006 年之後獲得或變更的。
          </p>
          <p>
            如果房屋用於出租，則需要繳納租賃收入稅。例如，年租賃收入達到 12,000
            歐元，稅率為 15%；年租賃收入在 12,001 到 35,000 歐元之間，稅率為
            35%；年租賃收入在 35,000 歐元以上，稅率為 45%。
          </p>
          <p>
            另外，從 2018 年 1 月 1
            日起，希臘政府還實施一項新的稅收政策，即針對個人房屋所有者在出售房屋時產生的收益部分（即買入、轉售的差價）徵收
            15%的稅收。
          </p>
        </Paragraph>
      </Section>

      <Section title="費用清單" px={1}>
        <TableTitle title="遞交申請前費用" />
        <StyledTable>
          <thead>
            <tr>
              <th scope="col">編號</th>
              <th scope="col">項目</th>
              <th scope="col">幣種</th>
              <th scope="col">金額</th>
              <th scope="col">折港幣</th>
              <th scope="col">說明</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>服務費</td>
              <td>港幣</td>
              <td>28,000</td>
              <td>28,000</td>
              <td>簽約時繳付</td>
            </tr>
            <tr>
              <td rowSpan={2}>2</td>
              <td rowSpan={2}>開立銀行賬戶費用</td>
              <td rowSpan={2}>歐元</td>
              <td>資料公証費約100-160/本</td>
              <td>880</td>
              <td>
                以公証1本資料,
                100歐元/本為例計算;具體費用視乎需要公証的數據而定;
                由客戶直接支付
              </td>
            </tr>
            <tr>
              <td>銀行開戶費100</td>
              <td>880</td>
              <td>由銀行收取, 客戶直接支付</td>
            </tr>
            <tr>
              <td>3</td>
              <td>醫療保險</td>
              <td>歐元</td>
              <td>約150-550/人/年</td>
              <td>3,960</td>
              <td>
                以一家三口, 一年150歐元保費為例計算;
                按申請人年齡及保險公司不同而有區別; 文件準備時, 律師可代為購買
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>資料費</td>
              <td>港幣</td>
              <td>約10,000</td>
              <td>10,000</td>
              <td>根據不同情況以相關單位的收費標準為準</td>
            </tr>
            <tr>
              <td colSpan={3}>合計</td>
              <td colSpan={2}>約4.4萬港幣</td>
              <td>以一家三口為例計算 (不含續期費)</td>
            </tr>
          </tbody>
        </StyledTable>

        <TableTitle title="官方費用" />
        <StyledTable>
          <thead>
            <tr>
              <th scope="col">編號</th>
              <th scope="col">項目</th>
              <th scope="col">幣種</th>
              <th scope="col">金額</th>
              <th scope="col">折港幣</th>
              <th scope="col">說明</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>申請簽証費</td>
              <td>歐元</td>
              <td>60/人</td>
              <td>1,584</td>
              <td>
                以一家三口為例計算; 按相關滙率支付港幣給簽証中心; 除簽証費外,
                還需繳納200港幣/人的簽証中心費用;
                12歲以下兒童無需繳納申根簽証費, 但需繳納簽証中心費用
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>居留許可申請費</td>
              <td>歐元</td>
              <td>2,150</td>
              <td>18,920</td>
              <td>
                主申請人2000歐元, 附屬申請人150歐元/人, 18週歲以下隨行子女免費;
                以一家三口, 隨行子女年齡不足18週歲為例計算
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>居留許可制卡費</td>
              <td>歐元</td>
              <td>16/人</td>
              <td>422</td>
              <td>以一家三口為例計算, 遞交申請時繳納</td>
            </tr>
            <tr>
              <td colSpan={3}>合計</td>
              <td colSpan={2}>約2.1萬港幣</td>
              <td>以一家三口為例計算 (不含續期費)</td>
            </tr>
          </tbody>
        </StyledTable>

        <TableTitle title="購房款及相關稅費" />
        <StyledTable>
          <thead>
            <tr>
              <th scope="col">編號</th>
              <th scope="col">項目</th>
              <th scope="col">幣種</th>
              <th scope="col">金額</th>
              <th scope="col">折港幣</th>
              <th scope="col">說明</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>購房移民律師費</td>
              <td>歐元</td>
              <td>3,100</td>
              <td>27,280</td>
              <td>約房價的1.24%, 以房款25萬歐元為例計算</td>
            </tr>
            <tr>
              <td>2</td>
              <td>購房款</td>
              <td>歐元</td>
              <td>最低25萬</td>
              <td>220萬</td>
              <td>選定房產後, 與開發商談妥價格</td>
            </tr>
            <tr>
              <td>3</td>
              <td>房產交易稅</td>
              <td>歐元</td>
              <td>7,725</td>
              <td>67,980</td>
              <td>
                2006年以前獲得建築許可的房產繳納約房價的3.09%的TT稅;
                2006年以後獲得建築許可的房產繳納約房價的24%的VAT稅;
                <br />
                以房款25萬歐元, 2006年前獲得建築許可的房產為例計算
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>房產市政稅</td>
              <td>歐元</td>
              <td>225</td>
              <td>1,980</td>
              <td>
                2006年以前獲得建築許可的房產繳納, 約為交易稅的3%;
                2006年以後獲得建築許可的房產不需繳納此市政稅;
                <br />
                以2006年前獲得建築許可的房產為例計算
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>授權書公証費</td>
              <td>歐元</td>
              <td>100</td>
              <td>880</td>
              <td>
                約100-150歐元; 以100歐元的授權書公証費為例計算;
                具體費用視授權書頁數而定
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>房產交易公証費</td>
              <td>歐元</td>
              <td>3,100</td>
              <td>27,280</td>
              <td>約房價的1-1.5% (加VAT 24%); 以1% 的房產交易公証費為例計算</td>
            </tr>
            <tr>
              <td>7</td>
              <td>房產土地註冊費</td>
              <td>歐元</td>
              <td>1,860</td>
              <td>16,368</td>
              <td>
                約房價的0.6%-0.8% (加VAT 24%); 以0.6% 的房產土地註冊費為例計算
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>房產過戶稅務登記費</td>
              <td>歐元</td>
              <td>100</td>
              <td>880</td>
              <td>房產過戶時稅務登記的一次性費用</td>
            </tr>
            <tr>
              <td>9</td>
              <td>物業費</td>
              <td>歐元</td>
              <td>240</td>
              <td>2,112</td>
              <td>
                約20-100歐元/月;以20歐元/月, 按照1年的物業費為例計算;
                具體金額視不同的物管公司規定而定
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>房產托管費</td>
              <td>歐元</td>
              <td>600</td>
              <td>5,280</td>
              <td>
                600歐元/年/套; 以1套房產, 1年的房產托管費為例計算;
                包括幫客戶繳納水電費, 物業費, 報修等服務 (不包括實際支出費用)
                <br />
                如果是包租公寓, 則免托管費
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>年度房產稅</td>
              <td>歐元</td>
              <td>500</td>
              <td>4,400</td>
              <td>
                約為房價的0.2% - 0.45%; 以房款25萬歐元, 2%的年度房產稅為例計算;
                根據所購房產所在區域的均價, 房齡, 樓層等多方面因素決定
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>稅務代表費</td>
              <td>歐元</td>
              <td>800</td>
              <td>7,040</td>
              <td>
                800歐元/年; 每個家庭2套房產內的費用; 以1套房產,
                1年的稅務代表費為例計算
              </td>
            </tr>
            <tr>
              <td colSpan={3}>合計</td>
              <td colSpan={2}>約16.1萬港幣</td>
              <td>不含購房款</td>
            </tr>
          </tbody>
        </StyledTable>
      </Section>

      <CallToAction />
    </Layout>
  );
};

export default FeePage;

export const query = graphql`
  {
    bg: file(relativePath: { eq: "bg-5.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const StyledTable = styled(Table)`
  margin-bottom: 3rem;
  td {
     &:last-of-type {
      width: 35%;
      text-align: left;
    }
  }
}
`;

const TableTitle = ({ title }) => {
  return (
    <Box
      flexBox
      mt={[3]}
      mb={[2]}
      flexWrap="wrap"
      justifyContent={["center", "space-between"]}
      alignItems="center"
    >
      <Heading content={title}></Heading>

      <Text
        width={[1, 1 / 2]}
        textAlign={["center", "right"]}
        fontSize={["0.7rem", 2]}
        mt={["0.5rem"]}
        content="滙率： 1 歐元 = 8.8 港幣"
      ></Text>
    </Box>
  );
};

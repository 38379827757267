import styled from "styled-components"
import { media } from "../lib/theme"

export default styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 3rem;
  border-radius: 4px;
  overflow: hidden;
  display: block;
  overflow-x: auto;

  thead {
    background: ${props => props.theme.colors.primary};
    color: white;
    vertical-align: middle;
  }

  tbody {
    vertical-align: middle;
    border-color: inherit;
    background-color: ${props => props.theme.colors.secondary};
  }

  td {
    width: 10%;
    padding: 1.5rem 0.75rem;
    border-top: 1px solid #dee2e6;
    text-align: center;
  }

  th {
    padding: 1rem;
    text-align: center;
    }
  }

  ${media.lessThan("medium")} {
    white-space: nowrap;
    th,
    td {
      text-align: left;
    }
  }
`

import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { height, HeightProps } from "styled-system";

export default styled(BackgroundImage)<HeightProps & { position?: string }>`
  ${height}
  width: 100%;
  background-position: ${props =>
    props.position ? props.position : "center center"};
  background-repeat: no-repeat;
  background-size: cover;
`;

import { FluidObject } from "gatsby-image";
import React, { FC } from "react";
import { Box, Flex } from "rebass";
import StyledBackgroundImage from "./StyledComponent/StyledBackgroundImage";

type Props = {
  backgroundPosition?: string;
  backgroundImage: FluidObject;
};

const PageHeader: FC<Props> = ({
  backgroundImage,
  backgroundPosition,
  children
}) => {
  return (
    <StyledBackgroundImage
      position={backgroundPosition}
      fluid={backgroundImage}
      height={["40vh", 428]}
    >
      <div
        css={`
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.2);
        `}
      ></div>
      <Box
        pt={"5rem"}
        width={[1]}
        css={`
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        `}
      >
        <Flex
          width={1}
          px={5}
          py={2}
          justifyContent={["center"]}
          alignItems={["center"]}
          flexDirection="column"
        >
          {children}
        </Flex>
      </Box>
    </StyledBackgroundImage>
  );
};

export default PageHeader;
